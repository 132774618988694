.block-intro {
	position: relative;
	background-color: #F7F7F9;
	overflow: hidden;

	> .container {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&--box {
		display: flex;
		flex-direction: column;
		max-width: 370px;
		width: 100%;
		z-index: 2;
	}

	&--title {
		font-size: 46px;
		color: #050505;
		line-height: 1;
		margin-bottom: 45px;
	}

	&--text {
		font-size: 18px;
		font-weight: 300;
		color: #9F9F9F;
		line-height: 1.3;
	}

	&--image {
		max-width: 100%;
		min-width: 315px;
		min-height: 315px;
		height: auto;
		margin-top: 40px;
		margin-right: -120px;
		background: url('../img/notebook.jpg') center / contain no-repeat;
		padding-bottom: 74%;
		padding-left: 74%;
	}

	&--we {
		position: absolute;
		left: 20px;
		bottom: 70px;
		font-size: 19px;
		font-weight: 300;
		color: #000;
	}
}

.juice-name {
	position: absolute;
	top: 30px;
	left: 0;
	font-size: 341px;
	font-weight: 500;
	color: #fff;
	line-height: 0.8;
	letter-spacing: 15px;
	opacity: .5;
	writing-mode: vertical-lr;
}

.parallax {
	z-index: 1;
	position: absolute;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
