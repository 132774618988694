.block-presentation {
	position: relative;
	padding: 100px 0;
	background-color: #F7F7F9;

	&--bg-parallax-1 {
		display: none;
		position: absolute;
		width: 100px;
		height: 80px;
		background-image: url("../img/parallax/parallax_5.png");
		background-size: contain;
		background-repeat: no-repeat;
		transform: rotateZ(82deg);
		left: 85vw;
		top: 50px;
		z-index: 1;
	}

	&--bg-parallax-2 {
		position: absolute;
		width: 60px;
		height: 60px;
		background-image: url("../img/parallax/parallax_6.png");
		background-size: contain;
		background-repeat: no-repeat;
		left: 50vw;
		top: 20px;
		z-index: 1;
	}

	&--bg-parallax-3 {
		position: absolute;
		width: 160px;
		height: 160px;
		background-image: url("../img/parallax/parallax_7.png");
		background-size: contain;
		background-repeat: no-repeat;
		right: 87vw;
		top: 340px;
		z-index: 1;
	}

	&--bg-label {
		position: absolute;
		left: 57%;
		font-size: 340px;
		color: #ffffff;
		top: 35px;
		font-weight: 500;
		z-index: 0;
		pointer-events: none;
		text-shadow: 0 0 100px rgba(0,0,0,0.02);
		letter-spacing: -10px;
	}

	&--desc {
		margin-top: 35px;
		font-size: 18px;
		font-weight: 300;
		color: #9F9F9F;
		line-height: 1.3;
		width: 370px;
		z-index: 1;
		position: relative;
	}

	&--bottom {
		margin-top: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
		position: relative;
	}

	&--button {
		width: 330px;
		height: 80px;
		display: flex;
		padding: 20px !important;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		background: #F96333;
	}
}
