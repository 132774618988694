.one-review {
	display: flex;
	opacity: 0;
	pointer-events: none;
	transition: all .25s ease-in-out;

	&--appear {
		transition: all 1s ease-in-out;
		opacity: 1;
		pointer-events: auto;
	}

	&--disappear {
		opacity: 1;
	}

	&--emoji {
		width: 64px;
		min-width: 64px;
		height: 64px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin-right: 48px;
	}

	&--container {
		width: 100%;
	}

	&--company-author {
		display: flex;
		justify-content: space-between;
		text-align: right;
		align-items: flex-start;
	}

	&--company {
		font-size: 28px;
	}

	&--author {
		font-size: 16px;
		line-height: 1.3;
	}

	&--position {
		font-size: 14px;
		font-weight: 300;
		line-height: 1.3;
		color: #9F9F9F;
	}

	&--description {
		font-size: 18px;
		font-weight: 300;
		color: #9F9F9F;
		line-height: 1.3;
		margin-top: 40px;
		text-align: justify;
		transition: all 0.1s ease-in-out;
		overflow: hidden;

		p {
			margin: 0;
		}

		p + p {
			margin-top: 16px;
		}
	}

	&--controls {
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
	}

	&--original {

		.btn {
			display: inline-block;
		}
	}

	&--prev-next {
		display: flex;

		.one-review--original {
			display: none;
		}
	}

	&--prev, &--next  {
		height: 47px;
		width: 47px;
		background-size: 32px;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;

		&:hover {
			opacity: 0.5;
		}
	}

	&--prev {
		background-image: url("../img/icon-review-left.png");
		margin-left: -10px;
		margin-right: 30px;
	}

	&--next {
		background-image: url("../img/icon-review-right.png");
		margin-right: 90px;
	}
}
