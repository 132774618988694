.footer {
	margin-top: 100px;
	padding: 100px 0 200px;

	.container {
		position: relative;
	}

	&--head {
		display: flex;
		justify-content: space-between;
	}

	&--name {
		position: relative;
		font-size: 341px;
		font-weight: 500;
		color: #000;
		line-height: .7;
		letter-spacing: -13px;
		transition: all .2s ease-in-out;
		pointer-events: none;

		span {
			position: absolute;
			top: 50%;
			left: 10px;
			transform: translateY(-50%) rotate(45deg);
			width: 36px;
			height: 36px;
			background: #F96333;
		}

		&:before {
			content: '';
			position: absolute;
			top: 140px;
			left: 60px;
			background: url('../img/icon-footer-ice_1.png') center no-repeat;
			background-size: contain;
			width: 250px;
			height: 540px;
		}

		&:after {
			content: '';
			position: absolute;
			top: 100px;
			left: 140px;
			z-index: -1;
			background: url('../img/icon-footer-ice_2.jpg') center no-repeat;
			background-size: contain;
			width: 310px;
			height: 375px;
		}
	}

	&--address {
		display: flex;
		flex-direction: column;
		align-self: flex-end;
		max-width: 250px;

		p {
			margin: 0 0 30px;
			font-size: 17px;
			font-weight: 300;
			color: #9F9F9F;
		}

		span {
			font-size: 15px;
			font-weight: 300;
			line-height: 1.3;
			color: #000;
		}
	}

	&--copy {
		position: absolute;
		top: 70%;
		left: 40px;
		transform: translateY(-50%) rotate(-180deg);
		font-size: 20px;
		writing-mode: vertical-lr;
	}

	&--bottom {
		z-index: 1;
		position: relative;
		margin-top: 65px;
		display: flex;
		flex-direction: column;
		width: 50%;
		margin-left: auto;
	}

	&--contact {
		display: flex;
		justify-content: space-between;

		a {
			font-size: 15px;
			font-weight: 300;
			color: #000;
			text-decoration: none;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	&--form {
		display: flex;
		flex-wrap: wrap;
		border: 1px solid #DEDEDE;
		background: #F9F9F9;
		padding: 0 20px;
		width: 100%;

		input {
			position: relative;
			font-family: 'Roboto', sans-serif;
			padding: 15px 15px 15px 0;
			border: none;
			font-size: 15px;
			font-weight: 300;
			color: #000;
			background: #F9F9F9;
			border-bottom: 1px solid #DEDEDE;
			outline: none;

			&:nth-child(1) {
				width: 150px;
			}

			&:nth-child(2) {
				padding: 15px 0 15px 15px;
				flex-grow: 1;
				border-left: 1px solid #dedede;
			}

			&::placeholder {
				font-family: 'Roboto', sans-serif;
				color: #9F9F9F;
			}
		}

		textarea {
			padding: 15px 0;
			width: 100%;
			height: 150px;
			border: none;
			resize: none;
			font-size: 15px;
			font-weight: 300;
			color: #000;
			outline: none;
			background: #F9F9F9;
			font-family: 'Roboto', sans-serif;

			&::placeholder {
				font-family: 'Roboto', sans-serif;
				color: #9F9F9F;
			}
		}
	}

	&--send {
		margin-top: 25px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.btn--send {
			margin-top: 25px;
		}
	}

	.contact-error {
		border-color: #F96333;

		&::placeholder {
			color: #F96333;
		}
	}
}
