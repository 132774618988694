@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

*, *:before, *:after {
	box-sizing: border-box;
}

div {
	display: block;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	margin: 0;
	padding: 0;
}

#root {
	overflow-x: hidden;
}

.modal {
	position: relative;
}

.container {
	max-width: 1120px;
	width: 100%;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
}

.block-head {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.block-title {
	position: relative;
	font-size: 37px;
	font-weight: 300;
	color: #050505;
	line-height: 1.1;
}

.block-subtitle {
	font-size: 25px;
	font-weight: 300;
	color: #9C9C9C;
	line-height: 1.1;
}

.btn {
	cursor: pointer;
	font-size: 18px;
	padding: 12px 40px;
	text-decoration: none;
	transition: all .2s ease-in-out;

	&--dark {
		border: 1px solid #000;
		background: #000;
		color: #fff;

		&:hover {
			background: #fff;
			color: #000;
		}
	}

	&--send {
		background: #F96333;
		color: #fff;

		&:hover {
			background: #FF8D4E;
		}
	}
}

.icon-vk {
	background: url('../img/icon-vk.svg') center no-repeat;
	background-size: contain;
	width: 70px;
	height: 70px;
	display: block;
	margin-left: -11px;
}

.icon-mail {
	background: url('../img/icon-mail.png') center no-repeat;
	background-size: contain;
	width: 44px;
	height: 44px;
	display: block;
}

h1 {
	margin: 0;
	font-weight: initial;
}
