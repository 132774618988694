.block-clients {
	padding: 50px 0;
	background: url('../img/icon-side-ice.jpg') left bottom no-repeat;
	background-size: 100%;

	&--box {
		display: flex;
		flex-wrap: wrap;
	}

	&--item {
		border: 1px solid #e8e8e8;
		width: 25%;
		margin: -0.5px;
		background: #fff;
		transition: all .2s ease-in-out;

		&:hover {
			transform: translateY(-10px);

			.block-clients--item__wrapp {
				filter: grayscale(0);
			}

			.block-clients--name {
				transform: translateY(0);
			}
		}

		&__wrapp {
			position: relative;
			padding-bottom: 100%;
			filter: grayscale(1);
			transition: all .2s ease-in-out;
			overflow: hidden;
		}

		&__empty {
			z-index: -1;
			border: none!important;
			box-shadow: none!important;
			font-size: 18px;
			font-weight: 300;
			color: #9F9F9F;
			transform: none!important;

			.block-clients--item__wrapp {
				filter: none;
			}

			.block-clients--inn {
				display: flex;
				align-items: center;
			}
		}
	}

	&--inn {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--image {
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		width: 150px;
		height: 150px;
	}

	&--name {
		position: absolute;
		left: 15px;
		bottom: 15px;
		transform: translateY(300%);
		transition: all .2s ease-in-out;
		font-size: 19px;
		color: #000;
	}
}
