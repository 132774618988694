.block-little {
	position: relative;
	padding: 100px 0;

	.container {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: -500px;
			left: 310px;
			background: url('../img/bg-little.jpg') top right no-repeat;
			background-size: contain;
			width: 1200px;
			height: 1500px;
			z-index: -1;
		}
	}

	&--content {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&--j {
		font-size: 620px;
		line-height: 1;
		font-weight: 500;
		color: #fff;
		text-shadow: 14px 12px 34px rgba(38, 38, 38, 0.12);
	}

	&--box {
		margin-top: 120px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 650px;
		width: 100%;
	}

	&--item {
		margin-bottom: 100px;
		display: flex;
		flex-direction: column;
		max-width: 280px;
		width: 100%;
	}

	&--title {
		margin-bottom: 20px;
		font-size: 46px;
		color: #050505;
	}

	&--text {
		font-size: 18px;
		font-weight: 300;
		color: #9F9F9F;
	}

	&--bottom {
		display: flex;
		align-items: center;

		div {
			display: flex;
			align-items: center;
		}

		span {
			margin-left: 25px;
			font-size: 18px;
			font-weight: 300;
			color: #A8A8A8;
		}
	}
}
