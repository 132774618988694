@media (max-width: 1280px) {
	.block-can {
		padding-bottom: 0;
	}

	.block-reviews {

		&--bg-1 {
			right: -100px;
			top: -100px;
		}

		&--bg-2 {
			left: -100px;
			bottom: -100px;
		}
	}

	.article .close {
		top: 50px;
		right: 20px;
	}
}

@media (max-width: 1120px) {
	.nav-menu {

		.icon-close {
			margin-right: 0;
		}
	}

	.block-little {

		.container:before {
			left: unset;
			right: -400px;
		}
	}

	.footer {

		&--name {
			font-size: 316px;

			&:before {
				width: 245px;
				height: 530px;
			}

			&:after {
				width: 305px;
				height: 365px;
			}
		}
	}

	.block-presentation {

		&--bg-parallax-1 {
			width: 100px;
			height: 80px;
			left: 75vw;
			top: 50px;
		}

		&--bg-parallax-3 {
			width: 120px;
			height: 120px;
			right: 92vw;
			top: 380px;
		}

		&--bg-label {
			left: 50%;
			font-size: 300px;
			top: 35px;
		}
	}

	.block-done {

		.block-head {

			&:before {
				top: -450px;
				right: 25%;
				width: 500px;
				height: 1200px;
			}
		}
	}

	.navbar {
		padding-top: 15px;
	}

	.container {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (max-width: 999px) {
	.parallax, .juice-name {
		display: none;
	}

	.block-intro {

		> .container {
			flex-direction: column-reverse;
			align-items: flex-start;
		}

		&--image {
			margin-left: auto;
			margin-right: auto;
		}

		&--box {
			position: relative;
			margin-top: -150px;
		}

		&--we {
			left: unset;
			right: 15px;
			bottom: 0;
			font-weight: 400;
		}
	}

	.block-can {
		padding-top: 100px;

		.container {
			flex-direction: column;
		}

		&--side-text {
			writing-mode: unset;
			transform: none;
			text-align: left;
			margin: 0;
		}

		&--box {
			margin: 90px -10px 0;

		}
	}

	.block-presentation {

		&--bg-parallax-1 {
			width: 100px;
			height: 80px;
			left: 75vw;
			top: 50px;
		}

		&--bg-parallax-3 {
			width: 120px;
			height: 120px;
			right: 92vw;
			top: 380px;
		}

		&--bg-label {
			left: 60%;
			font-size: 300px;
			top: 35px;
		}
	}

	.block-done {

		&:before {
			height: 28%;
		}

		.block-head {

			&:before {
				top: -270px;
				right: 10%;
				width: 400px;
				height: 685px;
			}
		}

		&--item {
			width: calc(50% - 30px);
		}
	}

	.block-little {

		.container:before {
			top: -470px;
			right: -200px;
			width: 930px;
		}

		.block-title {
			padding-top: 200px;
		}

		&--box {

		}

		&--j {
			position: absolute;
			top: -100px;
			right: 100px;
			font-size: 497px;
		}
	}

	.block-clients {

		&--box {
			margin-top: 50px;
		}

		&--item {
			width: 33%;
			order: 20;

			&:nth-child(3){
				order: 1;
			}
			&:nth-child(4){
				order: 2;
			}
			&:nth-child(5){
				order: 3;
			}

			&:nth-child(6){
				order: 4;
			}

			&__empty:nth-child(1){
				order: 4;

				.block-clients--inn {
					max-width: 230px;
					width: 100%;
					text-align: right;
					left: auto;
					padding-right: 20px;
				}
			}

			&__empty:nth-child(2){
				display: none;
			}
		}
	}

	.block-reviews {
		margin-top: 100px;
		padding-bottom: 30px;

		&--bg-1 {
			right: 10px;
			top: -350px;
		}

		&--bg-2 {
			left: -200px;
		}

		.block-title {
			font-size: 180px;
			letter-spacing: -7px;
		}

		&--review-block {
			width: 600px;
		}
	}

	.footer {
		padding-bottom: 100px;

		&--head {
			flex-direction: column;
		}

		&--copy {
			top: 62%;
		}

		&--name {
			span {
				top: unset;
				bottom: -150px;
			}
		}

		&--address {
			margin-top: 70px;

			span {
				margin-top: 45px;
			}
		}

		&--bottom {
			width: auto;
			margin-top: 100px;
		}

		&--contact {
			flex-direction: column;
			max-width: 250px;
			width: 100%;
			margin-left: auto;

			a {
				margin-bottom: 20px;
			}
		}

		&--send {
			margin-top: 70px;
		}
	}

	.modal--wrap {
		padding: 50px 0;
	}

	.article {

		&--headtitle {
			padding-top: 75px;
		}

		&--target__item:last-child {
			width: 100%;
		}

		&--target {
			position: relative;
			padding-bottom: 20px;

			&:after {
				content: '';
				position: absolute;
				background: url('../img/icon-ice-img.png') no-repeat;
				background-size: contain;
				width: 350px;
				height: 310px;
				bottom: -100px;
				right: 10%;
				filter: drop-shadow(44px 31px 36px rgba(170, 177, 197, 0.17));
			}
		}

		img {
			width: 100%;
			max-height: 450px;
			object-fit: cover;
		}

		&--task {
			flex-direction: column;
			padding: 100px 50px;

			&__info {
				margin: 0;
			}
		}

		&--title {
			margin-bottom: 50px;
		}

		&--idea {
			padding: 100px 50px;

			&__info {
				margin: 0;
				column-count: 1;
			}
		}

		&--brand {
			padding: 100px 50px;
		}

		&--result {
			padding: 100px 50px 0;

			&:before {
				top: -100px;
			}

			&__item {
				margin-bottom: 50px;
			}
		}

		&--footer {
			margin-top: -150px;
			padding-top: 220px;

			&:before {
				right: 50px;
			}

			.article--title {
				max-width: 300px;
				font-size: 46px;
			}

			.article--subtitle {
				max-width: 350px;

				br {
					display: none;
				}
			}

			&__name {
				right: 0;
			}
		}

		.footer--contact {
			max-width: 400px;
			flex-direction: row;
			margin-left: unset;
		}
	}

	.navbar {
		padding-top: 15px;
	}
}

@media (max-width: 700px) {
	.x-none {
		display: none;
	}

	.container {
		padding-left: 30px;
		padding-right: 30px;
	}

	.block-intro {
		min-height: auto;

		&--box {
			margin-top: 0;
		}

		&--title {
			font-size: 36px;
			margin-bottom: 25px;
		}

		&--text {
			font-size: 16px;
		}

		&--we {
			top: 60vw;
			left: 30px;
			right: unset;
			bottom: unset;
			font-size: 15px;
			font-weight: 400;
			writing-mode: vertical-lr;
			transform: translateY(-50%) rotate(180deg);
		}
	}

	.block-can {
		padding: 50px 0 100px 0;

		&--box {
			margin-top: 50px;
		}

		&--item {
			width: calc(50% - 20px);
			margin-bottom: 70px;
		}

		&--icon {
			width: 130px;
			margin-bottom: 20px;
		}

		&--text {
			font-size: 15px;
		}
	}

	.block-title {
		font-size: 30px;
		z-index: 1;

		span {

			&:after {
				content: " ";
			}
		}
	}

	.block-presentation {

		&--desc {
			width: 100%;
		}

		&--bg-label {
			left: 20px;
			top: -80px;
			font-size: 150px;
		}

		&--bg-parallax-1 {
			display: none;
		}

		&--bg-parallax-2 {
			top: -100px;
		}

		&--bg-parallax-3 {
			width: 100px;
			height: 100px;
			left: -10px;
			top: 380px;
		}

		.btn {
			padding-left: 35px;
			padding-right: 35px;
		}
	}

	.block-done {
		padding: 50px 0 100px 0;

		.block-head {
			flex-direction: column;
			align-items: baseline;

			&:before {
				top: -60px;
				right: -35px;
				width: 350px;
				height: 600px;
			}
		}

		.block-subtitle {
			font-size: 16px;
			margin-top: 30px;
			flex-direction: row-reverse;

			span {
				margin-left: 0;
				margin-right: 15px;
				width: 15px;
				height: 15px;
			}
		}

		&--box {
			margin-left: 0;
			margin-right: 0;
		}

		&--item {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}

		&--name {
			padding-top: 10px;
		}
	}

	.block-little {
		padding: 50px 0 100px 0;

		.container:before {
			top: -150px;
			right: -150px;
			width: 500px;
			height: 660px;
		}

		.block-title {
			padding: 0;
		}

		&--box {
			margin-top: 200px;
		}

		&--item {
			max-width: 255px;
			margin-bottom: 70px;
		}

		&--title {
			font-size: 38px;
			margin-bottom: 10px;
		}

		&--text {
			font-size: 16px;
		}

		&--j {
			font-size: 284px;
			top: 30px;
			right: 30px;
		}

		&--bottom {
			flex-direction: column;
			align-items: flex-start;

			span {
				margin-top: 15px;
				margin-left: 0;
				max-width: 250px;
			}
		}
	}

	.block-clients {
		padding: 50px 0 100px 0;

		&--box {

		}

		&--item {
			width: 50%;

			&:nth-child(1) {
				order: 5;
			}

			&__empty {
				font-size: 16px;
			}

			&:hover {
				transform: translateY(-5px) scale(1.01);
			}
		}

		&--name {
			font-size: 16px;
			left: 10px;
			bottom: 5px;
		}

		&--image {
			background-size: 60%;
		}
	}

	.block-reviews {
		padding-bottom: 0;

		&--bg-1 {
			right: -100px;
			width: 280px;
			height: 280px;
			top: -30px;
			z-index: 2;
		}

		&--bg-2 {
			top: auto;
			bottom: -250px;
		}

		.block-title {
			padding-top: 3.5vw;
			font-size: 22vw;
			letter-spacing: 0;
			text-shadow: 0 0 50px rgba(0, 0, 0, 0.02);
		}

		&--review-block {
			width: 100%;

			.one-review {
				display: block;

				&--emoji {
					width: 56px;
					min-width: 56px;
					height: 56px;
				}

				&--company-author {
					flex-direction: column;
					text-align: left;
				}

				&--company {
					margin-top: 20px;
				}

				&--author {
					margin-top: 26px;
				}

				&--description {
					font-size: 16px;
					margin-top: 36px;
				}

				&--prev-next {
					display: flex;
					width: calc(100% + 20px);
					justify-content: space-between;

					.one-review--prev, .one-review--next {
						margin: 0;
					}

					.one-review--prev {
						margin-left: -10px;
					}

					.one-review--next {
						margin-right: -10px;
					}

					.one-review--original {
						display: block;
					}
				}

				&--original {
					display: none;
				}

				.btn {
					padding: 12px 14px;
					font-size: 16px;
				}
			}
		}
	}

	.footer {
		padding: 50px 0 100px 0;

		&--head {

		}

		&--name {
			font-size: 160px;
			margin-left: -20px;
			letter-spacing: -8px;

			&:before {
				top: 70px;
				left: 20px;
				width: 150px;
				height: 330px;
			}

			&:after {
				top: 50px;
				left: 100px;
				width: 180px;
				height: 220px;
			}

			span {
				width: 23px;
				height: 23px;
				bottom: -200px;
			}
		}

		&--address {
			margin-top: 130px;
			max-width: 170px;
			text-align: right;

			p {
				display: none;
			}

			span {
				z-index: 1;
				line-height: 32px;
			}
		}

		&--bottom {
			margin-top: 40px;
		}

		&--contact {
			max-width: 170px;
			z-index: 1;
			text-align: right;

			a {
				margin-bottom: 15px;
			}
		}

		&--copy {
			top: 50%;
			left: 30px;
		}

		&--send {
			margin-top: 50px;
		}

		&--form {
			flex-direction: column;
			width: 100%;

			input {
				width: 100% !important;
				padding: 15px 0;

				&:nth-child(2){
					border-left: none;
					padding: 15px 0;
				}
			}

			textarea {
				padding: 15px 0;
			}
		}
	}

	.navbar {
		justify-content: space-between;
		padding-top: 15px;

		&--name, &--contact {
			display: none;
		}
	}

	.menu {

		&--container {
			margin: 0;
			min-height: 500px;
		}

		&--box {
			column-count: 1;
		}

		&--item {
			font-size: 25px;
		}

		&--address {
			font-size: 17px;
			line-height: 25px;
		}

		&--contact {
			display: flex;
		}
	}

	.icon-close-m {
		width: 35px;
		height: 35px;
	}

	.modal--wrap {
		padding: 0;
	}

	.article {

		.close {
			right: 10px;
		}

		.container {
			padding-left: 20px;
			padding-right: 20px;
		}

		&--headtitle {
			padding-left: 0;
			padding-right: 0;
			font-size: 33px;
		}

		&--target {
			padding-left: 0;
			padding-right: 0;

			&:after {
				width: 250px;
				height: 220px;
				bottom: -50px;
			}

			&__item:nth-child(3) {
				width: 30%;
			}

			&__text {
				font-size: 16px;
			}
		}

		img {
			margin-left: -20px;
			margin-right: -20px;
			max-width: calc(100% + 40px);
			width: calc(100% + 40px);
		}

		.article--title {
			font-size: 30px;
			margin-bottom: 15px;
		}

		&--task {
			padding: 50px 0;

			&__info {
				font-size: 16px;
			}
		}

		&--idea {
			padding: 50px 0;

			&__info {
				font-size: 16px;
			}
		}

		&--brand {
			padding: 50px 0;

			&__info {
				margin: 0;
				font-size: 16px;
			}
		}

		&--img-three img{
			width: unset !important;
			margin: 0 0 5px -20px!important;
		}

		&--result {
			padding: 50px 0;

			&:before {
				top: -50px;
				right: 0;
				width: 170px;
				height: 215px;
			}

			&__item {
				margin-bottom: 30px;

				span {
					font-size: 38px;
				}

				p {
					font-size: 16px;
				}
			}
		}

		&--footer {
			padding: 50px 0 50px;
			margin-top: 0;

			> div {
				position: relative;
			}

			&:before {
				top: -200px;
				right: -150px;
				width: 250px;
				height: 365px;
				background-size: contain;
			}

			&__name {
				font-size: 164px;
				top: 150px;
				right: -25px;
				transform: rotate(180deg);
				letter-spacing: 0;
			}
		}

		.article--title {
			font-size: 36px;
			max-width: unset;

			br {
				display: none;
			}
		}

		.article--subtitle {
			max-width: 250px;
		}

		.footer--contact, .footer--send {
			margin-top: 85px;
		}

		.footer--send {
			max-width: 100%;
		}

		.footer--form {
			width: 100%;
		}
	}
}
