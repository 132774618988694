.contact-icons {
	margin-bottom: 30px;
	margin-left: 30px;
	margin-right: 30px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	&__block {
		display: flex;
		gap: 12px;
	}

	&__vk {
		height: 32px;
		width: 32px;
		background: url('/media/icons/vk.svg') no-repeat center;
		background-size: cover;
	}

	&__tg {
		height: 32px;
		width: 32px;
		background: url('/media/icons/tg.svg') no-repeat center;
		background-size: cover;
	}

	&__rekvizit {
		text-align: right;
		font-size: 13px;
		font-weight: 300;
	}
}
