.block-can {
	padding: 20px 0;
	background-color: #F7F7F9;

	.container {
		position: relative;
		display: flex;
	}

	&--side-text {
		margin-top: 20px;
		font-size: 27px;
		font-weight: 300;
		color: #AFAFAF;
		text-align: right;
		writing-mode: vertical-lr;
		transform: rotate(-180deg);
		margin-left: -3px;
	}

	&--box {
		position: relative;
		margin-left: 70px;
		display: flex;
		flex-wrap: wrap;
		z-index: 1;
	}

	&--item {
		margin-bottom: 100px;
		margin-left: 10px;
		margin-right: 10px;
		display: flex;
		flex-direction: column;
		width: calc(33% - 20px);
	}

	&--icon {
		margin-bottom: 25px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: 0 center;
		width: 155px;
		height: 115px;
	}

	&--text {
		font-size: 21px;
		color: #070707;
	}
}

.icon--cms {
	background-image: url('../img/icon-cms.svg');
}
.icon--crm {
	background-image: url('../img/icon-crm.svg');
}
.icon--landing {
	background-image: url('../img/icon-landing.svg');
}
.icon--mobapps {
	background-image: url('../img/icon-mobapps.svg');
}
.icon--vkapps {
	background-image: url('../img/icon-vkapps.svg');
}
.icon--webapps {
	background-image: url('../img/icon-webapps.svg');
}
