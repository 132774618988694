.block-reviews {
	position: relative;
	z-index: 1;

	&:before {
		position: absolute;
		content: '';
		top: 20px;
		width: 1000vw;
		height: 100%;
		left: -500vw;
		background-color: rgba(239, 239, 244, 0.5);
		transform: rotateZ(3.4deg);
		z-index: -1;
	}

	.block-title {
		margin-top: 50px;
		margin-bottom: 60px;
		font-size: 220px;
		color: #ffffff;
		font-weight: 500;
		letter-spacing: -10px;
		text-align: center;
		line-height: 1;
		text-shadow: 0 0 100px rgba(0,0,0,0.02);
		pointer-events: none;
	}

	&--bg-1 {
		position: absolute;
		width: 520px;
		height: 520px;
		background-image: url("../img/parallax/parallax_8.png");
		background-size: contain;
		background-repeat: no-repeat;
		right: 0;
		top: -50px;
		z-index: 1;
	}

	&--bg-2 {
		position: absolute;
		width: 500px;
		height: 500px;
		background-image: url("../img/parallax/parallax_9.png");
		background-size: contain;
		background-repeat: no-repeat;
		left: -50px;
		bottom: -100px;
	}

	&--review-block {
		position: relative;
		width: 700px;
		margin-left: auto;
		margin-right: auto;
		z-index: 2;
		padding-bottom: 50px;
		transition: all 1s cubic-bezier(0, 0.9, 0.1, 1);
	}

	&--prev-review-block {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		pointer-events: none;
	}
}
