.header {
	position: absolute;
	left: 0;
	right: 0;
	z-index: 3;

	.navbar {
		justify-content: space-between;
	}

	.phone {
		font-weight: bold;
	}
}

.navbar {
	padding: 40px 0;
	display: flex;
	align-items: center;

	.nav-menu {
		cursor: pointer;
		padding: 5px;

		&:hover {
			opacity: 0.5;
		}
	}

	&--name {
		margin-left: 70px;
		font-size: 17px;
		color: #000;
	}

	&--contact {
		margin-left: auto;
		margin-right: 70px;
		display: flex;

		a {
			font-size: 15px;
			font-weight: bold;
			color: #000;
			text-decoration: none;

			&:hover {
				opacity: 0.5;
			}

			& + a {
				margin-left: 50px;
			}
		}
	}
}

.logo {
	background: url('../img/logo.png') center no-repeat;
	background-size: contain;
	width: 80px;
	min-width: 80px;
	height: 80px;
	display: block;
}

.icon-menu {
	background: url('../img/icon-menu.svg') center no-repeat;
	background-size: contain;
	width: 30px;
	height: 20px;
}

.icon-close {
	background: url('../img/icon-close.svg') center no-repeat;
	background-size: contain;
	width: 50px;
	height: 50px;
	margin-right: -10px;
	margin-left: -10px;
}
