.modal-owner {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	
	.modal {
		position: absolute;
		top: 50%;
		left: 50%;
		padding: 24px;
		box-sizing: border-box;
		background: #fff;
		transform: translate(-50%,-50%);
		font-family: 'Roboto', sans-serif;
		font-weight: 300;
	}
}

.modal {


	&__header {
		position: relative;
		padding-bottom: 24px;
		font-size: 18px;
		line-height: 21px;
		color: var(--mave-foreground-primary);

		&-close {
			position: absolute;
			top: -2px;
			right: 0;
			width: 24px;
			height: 24px;
			background-color: #000;
			cursor: pointer;
			mask-image: url('../img/close.svg') ;
			mask-repeat: no-repeat;

			&:hover {
				background-color: rgba(0,0,0,0.5);
			}
		}
	}

	&__body {
		overflow-y: scroll;
		max-height: calc(100vh - 160px);
		color: var(--mave-foreground-primary);

		&::-webkit-scrollbar {
			display: none;
			width: 0;
			height: 0;
			background-color: transparent;
		}
	}

	&__bottom-buttons {
		display: flex;
		justify-content: space-between;

		.button {
			margin-bottom: 0;
		}

		.button + .button {
			margin-left: 12px;
		}
	}
}
