.block-done {
	position: relative;
	padding: 50px 0;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: #F7F7F9;
		height: 32%;
		z-index: -2;
	}

	.block-head {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: -400px;
			right: 25%;
			background: url('../img/icon-big-ice.png') center no-repeat;
			background-size: contain;
			width: 550px;
			height: 1200px;
			filter: drop-shadow(54px 81px 36px rgba(170, 177, 197, 0.17));
		}
	}

	.block-subtitle {
		position: relative;
		display: flex;
		align-items: center;

		span {
			margin-left: 30px;
			width: 23px;
			height: 23px;
			border-radius: 50%;
			background: #F96232;
		}
	}

	&--box {
		margin: -15px;
		margin-top: 60px;
		display: flex;
		flex-wrap: wrap;
	}

	&--item {
		position: relative;
		margin: 15px;
		width: calc(33% - 27px);
		display: flex;
		flex-direction: column;

		&:hover {
			.block-done--image {
				transform: translateY(-10px);
			}
		}
	}

	&--image {
		width: 100%;
		height: 400px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		transition: all .2s ease-in-out;
	}

	&--name {
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 17px;
		color: #000;
	}

	&--bottom {
		margin-top: 35px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&--number {
		font-size: 26px;
		font-weight: 300;
		color: #000;
	}
}
