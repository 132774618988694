.block-menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 20;
	background-color: #fff;
	background-image: url('../img/menu-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	overflow-y: auto;

	&.show .menu--wrap {
		opacity: 1;
	}
}

.menu {

	&--open {
		overflow: hidden;
	}

	&--wrap {
		position: relative;
		margin: auto;
		padding: 0 20px;
		max-width: 1120px;
		flex-direction: column;
		width: 100%;
		height: 100%;
		min-height: 100%;
		display: flex;
		opacity: 0;
	}

	&--container {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: column;
		pointer-events: auto;
		background: #fff;
	}

	&--content {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
	}

	&--box {
		column-count: 2;
		max-width: 600px;
	}

	&--item {
		display: block;
		font-size: 36px;
		font-weight: 300;
		color: #050505;
		margin-bottom: 20px;
		cursor: pointer;
		user-select: none;

		&:hover {
			opacity: 0.5;
		}
	}

	&--address {
		display: flex;
		flex-direction: column;
		font-size: 25px;
		line-height: 40px;
		font-weight: 300;
		color: #000;

		span, a {
			color: #8F8F8F;
			text-decoration: none;
		}

		a:hover {
			color: #000000;
		}
	}

	&--contact {
		display: none;
		flex-direction: column;

		a {
			text-decoration: none;
			font-size: 15px;
			font-weight: 300;
			color: #000;

			& + a {
				margin-top: 10px;
			}
		}
	}
}
