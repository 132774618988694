.modal {

	&--open {
		overflow: hidden;
	}

	&--overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, .9);
		z-index: 25;
		overflow-y: auto;
	}

	&--wrap {
		position: relative;
		padding-top: 50px;
		padding-bottom: 50px;
	}
}

.icon-close-m {
	background: url('../img/icon-close-m.svg') center no-repeat;
	background-size: contain;
	width: 56px;
	height: 56px;
}

.article {
	position: relative;

	.container {
		background: #fff;
		overflow: hidden;
	}

	.close {
		position: fixed;
		top: 125px;
		right: 50px;
		cursor: pointer;
		z-index: 20;
	}

	&--headtitle {
		padding-top: 75px;
		padding-left: 50px;
		padding-right: 50px;
		font-size: 46px;
		color: #050505;
	}

	&--target {
		margin-top: 50px;
		padding-left: 50px;
		padding-right: 50px;
		display: flex;
		flex-wrap: wrap;

		&__item {
			position: relative;
			z-index: 1;
			margin-bottom: 50px;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}

		&__icon {
			width: 50px;
			height: 50px;
			background-repeat: no-repeat;
			background-size: contain;
		}

		&__text {
			margin-top: 15px;
			font-size: 18px;
			font-weight: 300;

			span {
				color: #9C9C9C;
			}

			p {
				margin: 10px 0 0;
				color: #212121;
			}
		}
	}

	img {
		position: relative;
		max-width: 100%;
		height: auto;
		display: block;
	}

	&--title {
		font-size: 37px;
		font-weight: 300;
		color: #050505;
		line-height: 1;
	}

	&--task {
		padding: 150px 50px 150px 50px;
		display: flex;

		&__info {
			margin-left: 90px;
			font-size: 18px;
			font-weight: 300;
			color: #9F9F9F;
			line-height: 1.3;
		}
	}

	&--idea {
		padding: 150px 50px 150px 50px;
		display: flex;
		flex-direction: column;

		&__info {
			margin-top: 50px;
			font-size: 18px;
			font-weight: 300;
			color: #9F9F9F;
			line-height: 1.3;
			column-count: 2;
			column-gap: 50px;
		}
	}

	&--brand {
		padding: 150px 50px 150px 50px;

		&__info {
			margin-top: 50px;
			font-size: 18px;
			font-weight: 300;
			color: #9F9F9F;
			line-height: 1.3;
		}
	}

	&--img-three {
		display: flex;
		flex-wrap: wrap;

		img:nth-child(1), img:nth-child(2) {
			width: calc(50% - 2.5px);
			margin-bottom: 5px;
		}

		img:nth-child(2) {
			margin-left: 5px;
		}
	}

	&--result {
		position: relative;
		padding: 150px 50px 50px 50px;

		&:before {
			content: '';
			position: absolute;
			top: -50px;
			right: 30px;
			background: url('../img/icon-article-ice.png') no-repeat;
			background-size: contain;
			width: 230px;
			height: 290px;
		}

		&__box {
			margin-top: 70px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		&__item {
			flex-grow: 1;
			max-width: 250px;

			span {
				font-size: 46px;
				color: #050505;
			}

			p {
				margin: 15px 0 0;
				font-size: 18px;
				font-weight: 300;
				color: #9F9F9F;
			}
		}
	}

	&--footer {
		position: relative;
		padding: 150px 50px 100px 50px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 150px;
			background: url('../img/icon-article-footer-ice.png') no-repeat;
			width: 340px;
			height: 495px;
			z-index: 1;
		}

		&__name {
			position: absolute!important;
			top: 0;
			right: 50px;
			font-size: 341px;
			font-weight: 500;
			color: #f3f3f3;
			line-height: 0.8;
			letter-spacing: -20px;
			writing-mode: vertical-lr;
			transform: rotate(180deg);
		}

		.article--title {
			font-size: 46px;
			font-weight: 400;
			line-height: 1;
		}

		.article--subtitle {
			margin-top: 30px;
			font-size: 17px;
			font-weight: 300;
			color: #9F9F9F;
		}
	}

	.footer--contact {
		margin-top: 70px;
		max-width: 550px;
		display: flex;
		flex-wrap: wrap;

		a {
			min-width: 200px;
		}
	}

	.footer--send {
		max-width: 550px;
	}
}
